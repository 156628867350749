import React from "react";
import Img from "../Assets/LAR-logo.jpg"

function Read() {
    return (
        <div className="w-full p-10">
            {/* <h1 className="text-xl font-medium md:text-2xl p-8">Our Foundation</h1> */}

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

<div className="flex flex-col p-3">
                    <h1 className="text-gray-600 tracking-wide font-medium text-xl">Let America Read</h1>
                    <p className="text-gray-600 tracking-wide mt-5">Let America Read is an initiative created in partnership with Campaign for Our Shared Future to help stop book bans and protect our children’s freedom to learn. </p>
                    <p className="text-gray-600 tracking-wide"></p>
                    <p className="text-gray-600 tracking-wide mt-5">Learn more about <a href="https://www.letamericaread.org/" target='_blank' rel='noopener noreferrer' className="text-red-600 cursor-pointer underline font-medium"> Let America Read.</a></p>
                </div>
            </div>
        </div>
    )
}

export default Read