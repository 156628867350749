import React, { useEffect, useState } from 'react';
import bas from '../Assets/basic.webp';
import bron from '../Assets/bronze.jpg';
import silv from '../Assets/silver.avif';
import gold from '../Assets/gold.webp';
import plat from '../Assets/plat.png';
import dia from '../Assets/dia.jpg';
import elite from '../Assets/elite.png';
import { Link } from 'react-router-dom';
import cardService from '../auth/card.card';

const imageMap = {
  'Basic Access Ticket': bas,
  'Bronze Access Ticket': bron,
  'Silver Access Ticket': silv,
  'Gold Access Ticket': gold,
  'Platinum Access Ticket': plat,
  'Diamond Access Ticket': dia,
  'Elite Access Ticket': elite,
};

function Tickets() {
  const [cardInfo, setCardInfo] = useState([]);

  useEffect(() => {
    const getCardInfo = async () => {
      try {
        const cardDetails = await cardService.getCardsInf();
        console.log("cardDetails", cardDetails?.data?.data);
        if (cardDetails?.data?.data) {
          setCardInfo(cardDetails?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getCardInfo();
  }, []);

  return (
    <div className='w-full mt-10'>
      <h1 className='text-black text-4xl md:text-6xl mx-auto text-center mt-[150px] md:mt-20'>Our Access Tickets</h1>
      <div className="relative p-6">
        <div className="absolute bottom-0 left-0 w-full">
          <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div>
          <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div>
          <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div>
        </div>
      </div>

      <div className='max-w-[1240px] grid p-2 grid-cols-2 md:grid-cols-3 gap-10 mx-auto mt-10 md:mt-12'>
{cardInfo?.map((card, index) => (
  card?.visibility ? (
    <div key={index} className="bg-gray-800 rounded-lg p-3 shadow-xl flex flex-col tracking-wider">
      {imageMap[card?.cardType] && (
        <img src={imageMap[card?.cardType]} alt={card?.cardType} className='rounded-full w-[200px] mx-auto' />
      )}
      <h1 className="font-bold text-white text-center text-2xl md:text-4xl">{card?.cardType}</h1>
      <p className="text-center text-gray-300 mt-3 text-xl md:text-2xl font-bold">${card?.price}</p>
      <Link to='signuppage'>
        <p className='bg-black text-white p-3 text-center mt-3 mx-auto hover:bg-green-600 rounded-xl'>Purchase Now</p>
      </Link>
      <div className="flex justify-center mt-2">
        {
          Array.from({length: card?.rating}).map((_, i) => (
            <img key={i} width="38" height="38" src="https://img.icons8.com/fluency/38/star--v1.png" alt="star--v1"/>
          ))
        }
      </div>
    </div>
  ) : null
))}
      </div>
    </div>
  );
}

export default Tickets;
