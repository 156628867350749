// // src/Components/ReviewCom.js
// import React from "react";
// import reviewdata from "./ReviewInput"; 
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// function ReviewCom() {
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 4000, 
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000, 
//     responsive: [
//         {
//             breakpoint: 1024,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//                 infinite: true,
//                 dots: false
//             }
//         },
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 1,
//                 initialSlide: 2
//             }
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 dots: false
//             }
//         }
//     ]
// };

//   return (
//     <div className="w-full p-10 mt-[-100px] md:mt-[-50px]">
//        <h1 className='text-black text-4xl md:text-6xl mx-auto text-center mt-[150px] md:mt-20'>Our Reviews</h1>
//             <div className="relative p-6"> 
//              <div className="absolute bottom-0 left-0 w-full">
//                <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
//                  <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
//                      <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
//                      </div>
//                  </div>
     
//       <div className='mt-10 mx-auto overflow-x-hidden overflow-y-hidden'>
//         <Slider {...settings}>
//           {reviewdata.map((review, id) => (
//             <div key={id} className='px-2'> {/* Add padding to create space */}
//               <div className='shadow-xl flex flex-col p-4 my-4 rounded-lg bg-black text-white'>
//                 <h1 className="font-bold text-center text-xl">{review.name}</h1>
//                 <p className='text-center font-medium'>
//                   {review.review}
//                 </p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// }

// export default ReviewCom;


import React from 'react'
import rev1 from '../Assets/Rev-1.jpg'
import rev5 from '../Assets/rev-5.jpg'
import rev12 from '../Assets/rev-12.jpg'
import { Link } from 'react-router-dom'

function ReviewCom() {
  return (
    <div className='w-full'>
<h1 className='text-black text-4xl md:text-6xl mx-auto text-center mt-[150px] md:mt-20'>Our Reviews</h1>
             <div className="relative p-6"> 
              <div className="absolute bottom-0 left-0 w-full">
                <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                  <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                      <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                      </div>
                  </div>

      <div className='max-w-[1240px] grid p-2 grid-cols-2 md:grid-cols-3 gap-10 mx-auto mt-10 md:mt-12'>
        <div className='bg-black rounded-lg p-3 shadow-xl flex flex-col'>
            <img src={rev1} alt='/img' className='rounded-full w-[100px] mx-auto' />
            <h1 className="font-bold text-white  text-center text-xl">John Stewart</h1>
            <p className='text-center text-gray-300 mt-3 font-medium'>
            This website is amazing! I got to chat with my favorite celebrity manager and it was an incredible experience.
            </p>
        </div>

        <div className='bg-black rounded-lg p-3 shadow-xl flex flex-col'>
            <img src={rev5} alt='/img' className='rounded-full w-[100px] mx-auto' />
            <h1 className="font-bold text-white  text-center text-xl">Will Pullock</h1>
            <p className='text-center text-gray-300 mt-3 font-medium'>
            A wonderful experience. I got to meet my favorite celebrity and it was unforgettable.
            </p>
        </div>

        <div className='bg-black rounded-lg p-3 shadow-xl flex flex-col'>
            <img src={rev12} alt='/img' className='rounded-full w-[100px] mx-auto' />
            <h1 className="font-bold text-white  text-center text-xl">Foster Rodriguez</h1>
            <p className='text-center text-gray-300 mt-3 font-medium'>
            I can’t believe I actually got to meet a celebrity! This site is wonderful.
            </p>
        </div>
      </div>

      <Link to='/reviewspage'><h1 className='text-center mt-10 md:mt-12 text-xl mx-auto underline tracking-wider hover:text-red-600'>See more Reviews</h1></Link>
    </div>
  )
}

export default ReviewCom