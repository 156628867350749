import React from "react";
import Img from "../Assets/CA_logos-07.png"

function Initiative() {
    return (
        <div className="w-full p-10">
            <h1 className="text-xl font-medium md:text-2xl p-8">Our Initiatives</h1>

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

                <div className="flex flex-col p-3">
                    <h1 className="font-medium text-gray-600 text-xl">Civic Alliance</h1>
                    <p className="text-gray-600 tracking-wide mt-5">Founded by the CAA Foundation and Democracy Works, the Civic Alliance is a non-partisan business coalition working together to build a future where all Americans participate in shaping the nation.</p>
                    <p className="text-gray-600 tracking-wide mt-5">Civic Alliance has grown to a community of more than 1,300 companies, with a reach of more than 5.5 million employees and hundreds of millions of consumers.</p>
                    <p className="text-gray-600 tracking-wide mt-5">Learn more about <a href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.civicalliance.com%2F&data=04%7C01%7Csasha.smithasst%40caa.com%7Cfd83bd6a75764c6efaba08d8c2f7af81%7Cc8f08d7a5d314558b0cab619c9a3d60a%7C0%7C0%7C637473716342256426%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=p6UShFCypzOSPsSYEHNgsNbwXJeYPYm1bKIvFchj38w%3D&reserved=0" target='_blank' rel='noopener noreferrer' className="text-red-600 underline font-medium">Civic Alliance.</a></p>
                </div>
            </div>
        </div>
    )
}

export default Initiative