import React from "react";


function Footer() {
    return(
        <div className="w-full bg-black p-5">
            
            <p className="text-white text-center">©Copyright 2024 <span className="font-bold text-xl">CAA</span> All Rights Reserved</p>
            
        </div>
    )
}

export default Footer