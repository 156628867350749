import React from "react";

function Foundation() {
    return (
        <div className="w-full p-10 mt-5 md:mt-10">
            <h1 className="text-2xl md:text-4xl p-8">Our Foundation</h1>

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <h1 className="text-4xl md:text-6xl p-6 font-medium">
                We are cultural change-makers accelerating activism.
                </h1>

                <div className="flex flex-col p-3 mt-5">
                    <p className="text-gray-600 tracking-wide">For more than 25 years, the CAA Foundation has been at the heart of the company—investing in our communities, amplifying causes that impact culture, and helping our clients reach their philanthropic goals. We mobilize on timely initiatives for positive social change by utilizing our convening abilities, broadening public and private partnerships, driving innovative campaigns, and creating scalable solutions. </p>
                    <p className="text-gray-600 mt-5 tracking-wide">Our work continues to evolve, meeting pressing challenges. From bringing the entertainment industry together around An Inconvenient Truth and helping to amplify the conversation around the environment, to supporting COVID-19 healthcare workers with #FIRSTRESPONDERSFIRST, CAA Foundation has worked with clients, partners and colleagues to drive meaningful change.</p>
                </div>
                </div>
        </div>
    )
}

export default Foundation