const Reachdata = [
    {
        id:1,
        country:"Los Angeles",
        address1:"2000 Avenue of the Stars",
        address2:"Los Angeles, CA 90067",
        address3:"10250 Constellation Blvd.",
        address4:"Los Angeles, CA 90067",
    },
    {
        id:2,
        country:"New York",
        address1:"405 Lexington Avenue, 22nd Floor",
        address2:"New York, NY 10174",
        address3:"65 E. 55th St.",
        address4:"New York, NY 10022",
    },
    {
        id:3,
        country:"Nashville",
        address1:"401 Commerce Street, Penthouse",
        address2:"Nashville, TN 37219",
    },
    {
        id:4,
        country:"Washington D.C.",
        address1:"1500 K Street NW",
        address2:"Washington DC, 20005",
    },
    {
        id:5,
        country:"London",
        address1:"12 Hammersmith Grove",
        address2:"London W6 7AP",
        address3:"United Kingdom",
    },
    {
        id:6,
        country:"Beijing",
        address1:"6th Floor, Room 601",
        address2:"Office Building W1, Beijing Oriental Plaza",
        address3:"No. 1 East Changan Avenue",
        address4:"Dongcheng District",
        address5:"Beijing 100006",
        address6:"China",
    },
    {
        id:7,
        country:"Shanghai",
        address1:"Unit 2704-2705,",
        address2:"Tower 3",
        address3:"Shanghai Jing An Kerry Center",
        address4:"1228 Yan An Middle Road",
        address5:"Shanghai, China 200040",
    },
    {
        id:8,
        country:"Singapore",
        address1:"88 Market Street, 40th Floor",
        address2:"Singapore 048948",
    },
    {
        id:9,
        country:"Atlanta",
        address1:"3560 Lenox Road, Suite 1525",
        address2:"Atlanta, GA 30326",
    },
    {
        id:10,
        country:"Miami",
        address1:"1688 Meridian Ave , Suite 340",
        address2:"Miami Beach, FL 33139",
    },
    {
        id:11,
        country:"Chicago",
        address1:"444 N. Michigan Avenue, Suite 3540",
        address2:"Chicago, IL 60611",
    },
    {
        id:12,
        country:"Denver",
        address1:"CAA ICON",
        address2:"5075 S. Syracuse Street, Suite 700",
        address3:"Denver, CO 80237",
    },
    {
        id:13,
        country:"Jacksonville",
        address1:"3652 South Third Street, Suite 200",
        address2:"Jacksonville Beach, FL 32250",
    },
    {
        id:14,
        country:"Memphis",
        address1:"6075 Poplar Ave, Suite 410",
        address2:"Memphis, TN 38119",
    },
    {
        id:15,
        country:"Stockholm",
        address1:"CAA Sports Sweden AB",
        address2:"Riggvagen 8",
        address3:"178 51 Ekerö",
        address4:"Sweden",
    },
    {
        id:16,
        country:"Munich",
        address1:"Infanteriestr. 11A, Haus C",
        address2:"80797 Munich",
        address3:"Germany",
    },
    {
        id:17,
        country:"Geneva",
        address1:"405 Lexington Avenue, 22nd FloorCAA Eleven Sàrl",
        address2:"Allée de la Petite Prairie 8-10",
        address3:"1260 Nyon",
        address4:"Switzerland",
    },
]

export default Reachdata