import React from "react";
import Img from "../Assets/FullStoryInitiative_Logo.jpg"

function FullStory() {
    return (
        <div className="w-full p-10">
            {/* <h1 className="text-xl font-medium md:text-2xl p-8">Our Foundation</h1> */}

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

<div className="flex flex-col p-3">
                    <h1 className="text-gray-600 tracking-wide font-medium text-xl">Full Story Initiative</h1>
                    <p className="text-gray-600 tracking-wide mt-5">The Full Story Initiative provides infrastructure within the entertainment industry to encourage more accurate portrayals of complex social issues and historically underrepresented communities in television and film.</p>
                    <p className="text-gray-600 tracking-wide"></p>
                    <p className="text-gray-600 tracking-wide mt-5">Learn more about the<a href="https://www.fullstoryinitiative.com/" target='_blank' rel='noopener noreferrer' className="text-red-600 cursor-pointer underline font-medium"> Full Story Initiative.</a></p>
                </div>
            </div>
        </div>
    )
}

export default FullStory