import React,{useState} from "react";
import { FaArrowLeft, FaEnvelope, FaKey } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import authClass from "../auth/user.class";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthUser } from "../auth/auth";
import { redirect } from "react-router-dom";
function SignIn() {
  const [isLoading,setIsLoading] = useState(false)
  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async(values) => {
      //console.log("Form values:", values);
      // You can handle form submission here (e.g., send data to the backend)
      try{
        //console.log(values);
      // Submit form or perform any action here
        setIsLoading(true)
        const res = await authClass.login(values)
        toast(res?.data?.message)
        setIsLoading(false)
        //console.log("res?.data?.status",res?.data?.status)
        if(res?.data?.status === 200){
          setAuthUser(res?.data?.token)
          window.location.href = "/fanchat"
          //return redirect("/chatpage");
        }
        
      }catch(err){
        setIsLoading(false)
        //console.log(err)
      }
    },
  });

  return (
    <div className="w-full">
      <h1 className="text-black text-2xl md:text-4xl mx-auto text-center mt-[50px] md:mt-10 font-bold">
        Sign Back In!
      </h1>
      <div className="relative p-6">
        <div className="absolute bottom-0 left-0 w-full">
          <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div>
          <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div>
          <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div>
        </div>
      </div>

      <div className="flex relative">
        <Link to="/">
          <FaArrowLeft className="absolute mx-2 mt-1" />
          <h1 className="underline 2xl mx-8 font-bold">Go Back</h1>
        </Link>
      </div>

      <div className="flex w-full min-h-screen justify-center items-center pb-[4rem] mt-10">
        <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-black w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
          <div className="relative flex justify-center items-center w-full">
            <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
            <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
            <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
              <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center">
                <h1 className="mx-auto font-bold pt-4 pb-6">Email</h1>
                <FaEnvelope className="mx-auto mb-3" />
                <input
                  name="email"
                  className="mx-auto p-2 border border-gray-400 rounded-md w-[200px] "
                  placeholder="name@gmail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-center">{formik.errors.email}</p>
                ) : null}

                <h1 className="mx-auto font-bold pt-4 pb-6">Password</h1>
                <FaKey className="mx-auto mb-3" />
                <input
                  name="password"
                  className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
                  placeholder="Insert Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-500 text-center">{formik.errors.password}</p>
                ) : null}

                <button
                  type="submit"
                  className="bg-black mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:underline  hover:text-white"
                >
                 {isLoading ? "Loading..." : " Login"}
                </button>
                <p className="text-black mx-auto pb-6">
                  Don't have an account?{" "}
                  <span className="font-bold">
                    <Link to="/signuppage">Sign Up</Link>
                  </span>
                </p>
                <p className="text-black mx-auto">
                  <p className="text-center">
                    ©Copyright 2024 <span className="font-bold">CAA</span> All Rights Reserved
                  </p>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
      <Footer />
    </div>
  );
}

export default SignIn;
