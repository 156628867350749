import React from "react";

function Focus() {
    return (
        <div className="w-full p-10 mt-5 md:mt-20">
            <h1 className="text-2xl md:text-4xl p-8">Our Areas of Focus</h1>

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <h1 className="text-4xl md:text-6xl p-6 font-medium">
                    We create solutions to reach big, challenging goals.
                </h1>

                <div className="flex flex-col p-3">
                    <h2 className="font-bold text-gray-600">For our Future: Public Education</h2>
                    <p className="text-gray-600">We lead and provide support for initiatives that create more resilient and equitable public education.</p>

                    <h2 className="font-bold text-gray-600 mt-3">For our Democracy: Civic Engagement</h2>
                    <p className="text-gray-600">Through strategic partnerships and coalition building, we work to strengthen democracy by inspiring civic engagement and helping increase voter turnout. </p>

                    <h2 className="font-bold text-gray-600 mt-3">For our Planet: Climate Crisis</h2>
                    <p className="text-gray-600">We mobilize our network of culture makers to achieve maximum public awareness, provide an opportunity for action and inspire a global network of climate activists.</p>

                    <h2 className="font-bold text-gray-600 mt-3">For Emergencies: Crisis Response</h2>
                    <p className="text-gray-600">We provide immediate support and amplify critical needs of organizations working in communities impacted by a crisis.</p>
                </div>
            </div>
        </div>
    )
}

export default Focus