import api from "./api"

class AuthService {
   login = async(data)=>{
      try{
        const response = await api.post('/auth/login',data)
        return response
      }catch(err){
        //console.log(err);
        return err
      }
    }
   register = async(data)=>{
        try{
          const response = await api.post('/auth/register',data)
          return response
        }catch(err){
          //console.log(err);
          return err
        }
    }
     getAuthUser = async()=>{
        try{
            const response = await api.get('/auth/user')
            return response
        }catch(err){
            //console.log(err);
            return err
        }
      }

}

const authClass = new AuthService();
export default authClass;