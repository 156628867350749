import React from 'react'
import reviewdata from '../Components/ReviewInput'
import Footer from '../Components/Footer'

function Reviews() {
    return (
        <div className='w-full'>
            <h1 className='text-black text-4xl md:text-6xl mx-auto text-center mt-[150px] md:mt-20'>Reviews About Us</h1>
            <div className="relative p-6">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                    <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                    <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                </div>
            </div>


            <div className='max-w-[1240px] grid p-2 grid-cols-2 md:grid-cols-3 gap-10 mx-auto mt-10 md:mt-12'>
                {reviewdata.map((item, id) => (
                    <div  key={id} className='bg-black rounded-lg p-3 shadow-xl flex flex-col'>
                        <img src={item.image} alt='/img' className='rounded-full w-[100px] mx-auto' />
                        <h1 className="font-bold text-white  text-center text-xl">{item.name}</h1>
                        <p className='text-center text-gray-300 mt-3 font-medium'>
                            {item.review}
                        </p>


                    </div>
                ))}


            </div>
                <Footer/>
        </div>
    )
}

export default Reviews