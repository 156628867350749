import React from "react";
import Img from "../Assets/iamavoter_logo_stacked_white_0.jpg"

function Voter() {
    return (
        <div className="w-full p-10">
            {/* <h1 className="text-xl font-medium md:text-2xl p-8">Our Foundation</h1> */}

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

<div className="flex flex-col p-3">
                    <h1 className="text-gray-600 tracking-wide font-medium text-xl mt-5">I am a voter.</h1>
                    <p className="text-gray-600 tracking-wide mt-5">Created in 2018 and with CAA as the Founding Partner, I am a voter. is a nonpartisan campaign that aims to create a cultural shift around voting and civic engagement. Our mission is to make voter participation mainstream, aspirational, and core to each person’s identity.</p>
                    <p className="text-gray-600 tracking-wide mt-5"></p>
                    <p className="text-gray-600 tracking-wide ">Learn more about<a href="https://iamavoter.com/" target='_blank' rel='noopener noreferrer' className="text-red-600 cursor-pointer font-medium underline"> I am a voter.</a></p>
                </div>
            </div>
        </div>
    )
}

export default Voter