import api from "./api"
class CardClass{
    getCardsInf = async()=>{
        try{
            const response = await api.get('/card')
            return response
          }catch(err){
            //console.log(err);
            return err
          } 
    }
}

const cardService = new CardClass();
export default cardService