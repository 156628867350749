import React from "react";
import Img from "../Assets/RF-logo.jpg"

function Responders() {
    return (
        <div className="w-full p-10">
            {/* <h1 className="text-xl font-medium md:text-2xl p-8">Our Foundation</h1> */}

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

<div className="flex flex-col p-3">
                    <h1 className="text-gray-600 tracking-wide font-medium text-xl">First Responders First</h1>
                    <p className="text-gray-600 tracking-wide mt-5">#FIRSTRESPONDERSFIRST is an initiative created by the Harvard T.H. Chan School of Public Health, Thrive Global, and the CAA Foundation to provide frontline healthcare workers with the physical and psychological support and resources they so desperately need as they serve on the frontlines of the coronavirus pandemic.</p>
                    <p className="text-gray-600 tracking-wide mt-5"></p>
                    <p className="text-gray-600 tracking-wide">Learn more about <a href="https://thriveglobal.com/categories/first-responders-first/" target='_blank' rel='noopener noreferrer' className="text-red-600 cursor-pointer underline font-medium"> First Responders First.</a></p>
                </div>
            </div>
        </div>
    )
}

export default Responders