import React, { useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Socket } from '../auth/socket';
import authClass from '../auth/user.class';

const FanChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [lowNetwork, setLowNetwork] = useState(false);
  const [user, setUser] = useState(null);
  const inputRef = useRef();
  const messagesEndRef = useRef();  // Add a ref for the end of the messages

  // Function to send message optimistically
  const sendMessage = useCallback(() => {
    if (!input.trim()) return;  // Don't send empty messages

    const newMessage = {
      _id: `${Date.now()}`,  // Generate a temporary unique ID
      message: input,
      type: 'message',
      receiverId: '66ef45cf9372397abcb53b1e',
      senderId: user?._id,
      sent: true,
      seen: false,
      createdAt: new Date().toISOString(),
    };

    // Optimistically add message to the UI
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Emit message through socket
    Socket.emit('privateMessage', newMessage, (response) => {
      if (response.status !== 'ok') {
        // Handle failure, e.g., mark message as not sent
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.createdAt === newMessage.createdAt ? { ...msg, sent: false } : msg
          )
        );
      }
    });

    // Clear the input after sending
    setInput('');
  }, [input, user]);

  // Fetch previous messages only once
  useEffect(() => {
    if (user) {
      Socket.emit('get_previous_messages', {
        senderId: user?._id,
        receiverId: '66ef45cf9372397abcb53b1e',
      });

      Socket.on('previous_messages', (data) => {
        // Sort messages by createdAt before setting them
        const sortedMessages = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setMessages((prevMessages) => [...prevMessages, ...sortedMessages]);
      });

      return () => {
        Socket.off('previous_messages');
      };
    }
  }, [user]);

  // Listen for incoming private messages
  useEffect(() => {
    const handlePrivateMessage = (newMessage) => {
      setMessages((prevMessages) => {
        // Check if the message already exists based on _id or createdAt
        const messageExists = prevMessages.some(msg => msg._id === newMessage._id);
        if (!messageExists) {
          const updatedMessages = [...prevMessages, newMessage];
          // Sort messages by createdAt to maintain order
          return updatedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }
        return prevMessages; // If message exists, do nothing
      });
    };

    Socket.on('privateMessage', handlePrivateMessage);

    return () => {
      Socket.off('privateMessage', handlePrivateMessage);
    };
  }, []);

  // Manage socket connection and network status
  useEffect(() => {
    Socket.emit('connection');

    Socket.on('connection_status', (data) => {
      setLowNetwork(!data);
    });

    Socket.on('connect_error', (error) => {
      setLowNetwork(true);
      console.error('Socket connection error:', error);
    });

    return () => {
      Socket.off('connection_status');
      Socket.off('connect_error');
    };
  }, []);

  // Fetch authenticated user on component mount
  useLayoutEffect(() => {
    const getAuthUser = async () => {
      try {
        const res = await authClass.getAuthUser();
        setUser(res?.data?.data);
      } catch (err) {
        console.error('Error fetching auth user:', err);
      }
    };
    getAuthUser();
  }, []);

  // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="p-4 bg-blue-500 text-white flex justify-between items-center">
        <h2 className="text-xl">Manager Chat</h2>
        <button
          onClick={() => setShowPaymentPopup(true)}
          className="bg-white text-blue-500 p-2 rounded"
        >
          Payment
        </button>
      </div>

      <div className="flex-grow p-4 overflow-y-auto">
        {messages.map((msg, index) => (
          <div
            key={msg._id || index}
            className={`mb-2 ${msg.senderId === user?._id ? 'text-right' : 'text-left'}`}
          >
            <span
              className={`inline-block p-2 rounded ${
                msg.senderId === user?._id ? 'bg-green-500 text-white' : 'bg-gray-300'
              }`}
            >
              {msg.message} {msg.sent === false && <span className="text-red-500"> (Failed)</span>}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />  {/* This div ensures we scroll to the bottom */}
      </div>

      <div className="p-4 flex border-t">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          ref={inputRef}
          className="flex-1 p-2 border rounded"
          placeholder="Type a message..."
        />
        <button
          onClick={sendMessage}
          className="ml-2 bg-green-500 text-white p-2 rounded"
        >
          Send
        </button>
      </div>

      {showPaymentPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg relative">
            <button
              onClick={() => setShowPaymentPopup(false)}
              className="absolute top-2 right-2 text-gray-600"
            >
              <FaTimes />
            </button>
            <h2 className="text-lg font-semibold mb-4">Payment</h2>
            <p className="text-gray-700">Wallet Address: bc1qcz8xswk0w4wfsrhnyeqly092ed2lt8azgkutdg</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FanChatBox;
