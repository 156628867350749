import React from "react";
import Img from "../Assets/SC-Fund.png"

function SocialChange() {
    return (
        <div className="w-full p-10">
            {/* <h1 className="text-xl font-medium md:text-2xl p-8">Our Foundation</h1> */}

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px]"
                />

<div className="flex flex-col p-3">
                    <h1 className="text-gray-600 tracking-wide font-medium text-xl mt-5">Social Change Fund United</h1>
                    <p className="text-gray-600 tracking-wide mt-5">The Social Change Fund United was created by Chris Paul, Dwyane Wade, and Carmelo Anthony in response to continued racial injustice. The organization seeks to utilize collective influence and resources to make a greater impact in underrepresented communities of color.</p>
                    <p className="text-gray-600 tracking-wide mt-5">The Social Change Fund United supports organizations that work in areas of safety and criminal justice, civic engagement, economic investment, arts and education, and health equity.</p>
                    <p className="text-gray-600 tracking-wide mt-5">Learn more about the <a href="https://www.thesocialchangefund.org/" target='_blank' rel='noopener noreferrer' className="text-red-600 cursor-pointer underline font-medium"> Social Change Fund United</a></p>
                </div>
            </div>
        </div>
    )
}

export default SocialChange