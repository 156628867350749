// import React from 'react';
// import Back from '../Assets/Back.jpg'
// import { Link } from 'react-router-dom';

// const Hero = () => {
//   return (
//     <div className="relative h-screen">
//       {/* Background Image */}
//       <div
//         className="absolute inset-0 bg-cover bg-center filter brightness-50 md:h-[50vh]">
//             <img
//              src={Back}
//              alt='/img'
//              className='md:h-[100vh] h-[400px] w-full'
//              />
//         </div>

//       {/* Navbar */}
//       <nav className="absolute top-0 left-0 right-0 flex justify-center items-center h-16 bg-transparent text-white">
//         <ul className="flex">
  
//           {/* <li className="mx-4">
//             <a href="#" className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl">About</a>
//           </li>
//           <li className="mx-4">
//             <a href="#" className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl">Contact</a>
//           </li> */}
//           <li className="mx-4">
//             <Link to='/signinpage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl">Sign In
//             </Link>
//           </li>
//         </ul>
//       </nav>

//       {/* Names on left and right */}
//       <div className="absolute top-0 left-0 h-16 flex items-center pl-4">
//         <h2 className="text-white p-4 text-2xl md:text-4xl font-bold">CAA</h2>
//       </div>
     

//       {/* Content */}
//       <div className="md:p-32 px-4 py-10 mt-13 md:mt-16 absolute justify-center top-0 left-0 right-0 flex flex-col">
//         <h1 className="text-3xl md:text-6xl font-bold text-slate-300 w-2/1 sm:w-2/2 md:mt-[-20px] mt-[50px] tracking-wide">CREATIVE AGENTS AGENCY</h1>

//         <Link to='/signuppage'><button className='mt-10 ml-10 text-xl font-medium bg-white p-4 hover:bg-black hover:text-white w-[300px]'>Interact With Management</button></Link>
       
//       </div>
//     </div>
//   );
// };

// export default Hero;





// import React, { useState } from 'react';
// import Back from '../Assets/Back.jpg';
// import { Link } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa';

// const Hero = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="relative h-screen">
//       {/* Background Image */}
//       <div className="absolute inset-0 bg-cover bg-center filter brightness-50 md:h-[50vh]">
//         <img
//           src={Back}
//           alt='/img'
//           className='md:h-[100vh] h-[400px] w-full'
//         />
//       </div>

//       {/* Navbar */}
//       <nav className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 bg-transparent text-white z-10 p-4">
//         <h2 className="text-white text-2xl md:text-4xl font-bold">CAA</h2>
//         <div className="md:hidden">
//           <button onClick={toggleMenu}>
//             {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
//           </button>
//         </div>
//         <ul className={`flex-col md:flex md:flex-row space-y-4 md:space-y-0 md:space-x-4 absolute md:static bg-transparent w-full md:w-auto transition-all duration-300 ease-in ${isOpen ? 'top-16' : 'top-[-400px]'}`}>
//           <li>
//             <Link to='/signinpage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Sign In</Link>
//           </li>
//           <li>
//             <Link to='/signinpage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Sign In</Link>
//           </li>
//           <li>
//             <Link to='/signinpage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Sign In</Link>
//           </li>
//         </ul>
//       </nav>

//       {/* Content */}
//       <div className="md:p-32 px-4 py-10 mt-13 md:mt-16 absolute top-0 left-0 right-0 flex flex-col items-center justify-center md:mt-0 mt-[100px]">
//         <h1 className="text-3xl md:text-6xl font-bold text-slate-300 tracking-wide text-center">CREATIVE AGENTS AGENCY</h1>
//         <Link to='/signuppage'>
//           <button className='mt-10 text-xl font-medium bg-white p-4 hover:bg-black hover:text-white w-[300px] rounded-md'>
//             Interact With Management
//           </button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Hero;









import React, { useState } from 'react';
import Back from '../Assets/Back.jpg';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToAbout = () => {
    document.getElementById('about-section').scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false); // Close the menu after clicking
  };

  const scrollToContact = () => {
    document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false); // Close the menu after clicking
  };

  return (
    <div className="relative h-screen overflow-hidden"> {/* Prevent page from floating */}
      {/* Background Image */}
      <div className="absolute inset-0 bg-cover bg-center filter brightness-50 md:h-[50vh]">
        <img
          src={Back}
          alt='/img'
          className='md:h-[100vh] h-[400px] w-full'
        />
      </div>

      {/* Navbar */}
      <nav className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 bg-transparent text-white z-10 p-4">
        <h2 className="text-white text-2xl md:text-4xl font-bold">CAA</h2>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
        <ul className={`flex-col md:flex md:flex-row space-y-4 md:space-y-0 md:space-x-4 absolute md:static bg-transparent w-full md:w-auto transition-all duration-300 ease-in ${isOpen ? 'top-16 bg-white text-black' : 'top-[-400px]'}`}>
          <li>
           <button className='flex mx-auto' onClick={scrollToAbout}> <Link to='/' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">About</Link>
           </button>
          </li>
          <li>
           <button className='text-center flex mx-auto' onClick={scrollToContact}><Link to='/' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Contact</Link>
           </button>
          </li>
          <li>
            <Link to='/reviewspage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Reviews</Link>
          </li>

          <li>
            <Link to='/signinpage' className="hover:underline font-medium tracking-wider hover:bg-white hover:text-black p-2 rounded-xl block text-center md:inline-block">Sign In</Link>
          </li>
        </ul>
      </nav>

      {/* Content */}
      <div className="md:p-32 px-4 py-10 mt-13 md:mt-16 absolute top-0 left-0 right-0 flex flex-col items-center justify-center  mt-[100px]">
        <h1 className="text-3xl md:text-6xl font-bold text-slate-300 tracking-wide text-center">CREATIVE AGENTS AGENCY</h1>
        <Link to='/fanchat'>
          <button className='mt-10 text-xl font-medium bg-white p-4 hover:bg-black hover:text-white w-[300px] rounded-md'>
            Interact With Management
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
