import React, { useEffect, useState } from 'react';
import { Socket } from '../auth/socket';

const ContactList = ({ selectContact }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Emit to get users if the list is still empty
      if (users.length === 0) {
        //console.log("Emitting getUser as users list is still empty");
        Socket.emit('getUser');
      }
    }, 1000); // retry every 3 seconds

    // Listen for 'user' event and update the user list
    const handleUserEvent = (data) => {
      //console.log("data of users",data)
      setUsers(data);
      // Clear the interval if users are found
      if (data.length > 0) {
        clearInterval(intervalId);
      }
    };

    Socket.on('user', handleUserEvent);

    // Cleanup listener and interval on component unmount
    return () => {
      Socket.off('user', handleUserEvent);
      clearInterval(intervalId);
    };
  }, [users.length]);

  return (
    <div className="w-full md:w-1/3 border-r border-gray-300">
      <h2 className="text-xl font-semibold p-4">Contacts</h2>
      <ul>
        {users.map((contact) => (
          <li
            key={contact?._id}
            onClick={() => selectContact(contact?._id)}
            className="cursor-pointer p-4 hover:bg-gray-200"
          >
            {contact?.fullName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactList;
