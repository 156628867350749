import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Socket } from '../auth/socket';
import authClass from '../auth/user.class';

const ChatWindow = ({ contact }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [lowNetwork, setLowNetwork] = useState(false);
  const [user, setUser] = useState(null);
  const inputRef = useRef();
  const messagesEndRef = useRef(null);  // Reference to the bottom of the message list

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Auto-scroll to the latest message when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Function to send message optimistically
  const sendMessage = useCallback(() => {
    if (!input.trim()) return;  // Don't send empty messages

    const newMessage = {
      _id: `${Date.now()}`,  // Generate a temporary unique ID
      message: input,
      type: 'message',
      receiverId: contact,
      senderId: user?._id,  // Use the current user's ID
      sent: true,
      seen: false,
      createdAt: new Date().toISOString(),
    };

    // Optimistically add message to the UI
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Emit message through socket
    Socket.emit('privateMessage', newMessage, (response) => {
      if (response.status !== 'ok') {
        // Handle failure, e.g., mark message as not sent
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === newMessage._id ? { ...msg, sent: false } : msg
          )
        );
      }
    });

    // Clear the input after sending
    setInput('');
  }, [input, user, contact]);

  // Fetch previous messages only once
  useEffect(() => {
    if (user) {
      Socket.emit('get_previous_messages', {
        senderId: user?._id,
        receiverId: contact,
      });

      Socket.on('previous_messages', (data) => {
        // Sort messages by createdAt before setting them
        const sortedMessages = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setMessages(sortedMessages);
      });

      return () => {
        Socket.off('previous_messages');
      };
    }
  }, [user, contact]);

  // Listen for incoming private messages
  useEffect(() => {
    const handlePrivateMessage = (newMessage) => {
      setMessages((prevMessages) => {
        // Check if the message already exists based on _id or createdAt
        const messageExists = prevMessages.some(msg => msg._id === newMessage._id);
        if (!messageExists) {
          const updatedMessages = [...prevMessages, newMessage];
          // Sort messages by createdAt to maintain order
          return updatedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }
        return prevMessages; // If message exists, do nothing
      });
    };

    Socket.on('privateMessage', handlePrivateMessage);

    return () => {
      Socket.off('privateMessage', handlePrivateMessage);
    };
  }, []);

  // Manage socket connection and network status
  useEffect(() => {
    Socket.emit('connection');

    Socket.on('connection_status', (data) => {
      setLowNetwork(!data);
    });

    Socket.on('connect_error', (error) => {
      setLowNetwork(true);
      console.error('Socket connection error:', error);
    });

    return () => {
      Socket.off('connection_status');
      Socket.off('connect_error');
    };
  }, []);

  // Fetch authenticated user on component mount
  useLayoutEffect(() => {
    const getAuthUser = async () => {
      try {
        const res = await authClass.getAuthUser();
        setUser(res?.data?.data);
      } catch (err) {
        console.error('Error fetching auth user:', err);
      }
    };
    getAuthUser();
  }, []);

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-grow p-4">
        <h2 className="text-xl font-semibold mb-4">Chat with {contact}</h2>
        <div className="h-80 overflow-y-auto border p-4 mb-4">
          {messages.map((msg, index) => (
            <div
              key={msg._id || index}  // Use _id as a key or fallback to index
              className={`mb-2 ${
                msg.senderId === user?._id ? 'text-right' : 'text-left'
              }`}
            >
              <span
                className={`inline-block p-2 rounded ${
                  msg.senderId === user?._id
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-300'
                }`}
              >
                {msg.message}
              </span>
            </div>
          ))}
          {/* Reference to the end of the message list */}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="border-t p-4 flex">
        <input
          type="text"
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="flex-1 p-2 border rounded"
          placeholder="Type a message..."
        />
        <button
          onClick={sendMessage}
          className="ml-2 bg-blue-500 text-white p-2 rounded"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
