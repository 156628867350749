import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ChatApp from "./Components/ChatApp";
import FanChatBox from "./Components/FanChatBox";
import React, { useState, useLayoutEffect } from "react";
import { getAuthUser } from "./auth/auth";
import authClass from "./auth/user.class";
import Reviews from "./Pages/Reviews";
import ScrollToTop from "./Components/ScrollToTop";

// ProtectedRoute component
const ProtectedRoute = ({ children, user, requiredRole, loading }) => {
  if (loading) {
    // Show a loading indicator while user data is being fetched
    return <div>Loading...</div>;
  }

  const isAuthenticated = getAuthUser();
  if (!isAuthenticated) {
    return <Navigate to="/signinpage" />;
  }
  //console.log("user?.role",user?.role,requiredRole)
  if (requiredRole && user?.role !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const fetchAuthUser = async () => {
      try {
        const res = await authClass.getAuthUser();
        setUser(res?.data?.data);
      } catch (err) {
        console.error("Error fetching auth user:", err);
      } finally {
        setLoading(false); // Set loading to false once the user is fetched
      }
    };
    fetchAuthUser();
  }, []);

  return (
    <div>
      <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* Public Routes */}
          <Route path="/" index element={<Home />} />
          <Route path="signinpage" element={<SignIn />} />
          <Route path="signuppage" element={<SignUp />} />
          <Route path="reviewspage" element={<Reviews/>} />
          {/* Protected Routes */}
          <Route
            path="chatpage"
            element={
              <ProtectedRoute user={user} requiredRole="admin" loading={loading}>
                <ChatApp />
              </ProtectedRoute>
            }
          />
          <Route
            path="fanchat"
            element={
              <ProtectedRoute user={user}  loading={loading}>
                <FanChatBox />
              </ProtectedRoute>
            }
          />
        </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
