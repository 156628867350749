import React from "react";
import Hero from "./Hero";
import About from "./About";
import ReviewCom from "./ReviewCom";
import Contact from "./Contact";
import Footer from "./Footer";
import Foundation from "./Foundation";
import Focus from "./Focus";
import Initiative from "./Initiative";
import SocialChange from "./SocialChange";
import Voter from "./Voter";
import Responders from "./Responders";
import FullStory from "./FullStory";
import Read from "./Read";
import Impact from "./Impact";
// import ChatApp from "./ChatApp";
import ReachOut from "./ReachOut";
import Tickets from "./Tickets";

function Home() {
    return(
        <div>
            <Hero />
            <About />
            <Tickets />
            <ReviewCom />
            <Foundation />
            <Focus />
            <Initiative />
            <SocialChange />
            <Voter />
            <Responders />
            <FullStory />
            <Read />
            <Impact />
            <ReachOut />
            <Contact />
            <Footer />
            {/* <ChatApp /> */}
        </div>
    )
}

export default Home
