import React from "react";
import Reachdata from "../Components/ReachInput"

function ReachOut() {
    return (
        <div className="w-full p-4">
            <h1 className="text-center mt-5 md:mt-20 text-4xl md:text-6xl font-medium">Reach Out To Us</h1>
            <div className="relative p-6">

                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                    <div className="h-0.5 bg-gray-800 w-[200px] mx-auto mb-2"></div> {/* Long line in the middle */}
                    <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                </div>
            </div>

            <div className="max-w-[1240px] grid grid-cols-2 gap-8 md:grid-cols-3 mx-auto mt-5 md:mt-20">
                {Reachdata.map((Reachdata, id) =>(

                <div className="flex flex-col mt-5 md:mt-10">
                    <h1 className="text-3xl text-center font-medium">{Reachdata.country}</h1>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address1}</p>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address2}</p>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address3}</p>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address4}</p>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address5}</p>
                    <p className="text-gray-600 text-center mt-3 tracking-wide">{Reachdata.address6}</p>
                </div>
                ))}
            </div>
        </div>
    )
}

export default ReachOut