import rev1 from '../Assets/Rev-1.jpg'
import rev2 from '../Assets/rev-2.jpg'
import rev3 from '../Assets/rev-3.jpg'
import rev4 from '../Assets/rev-4.jpg'
import rev5 from '../Assets/rev-5.jpg'
import rev6 from '../Assets/rev-33.jpg'
import rev7 from '../Assets/rev-32.avif'
import rev8 from '../Assets/rev-18.jpg'
import rev9 from '../Assets/rev-19.webp'
import rev10 from '../Assets/rev-21.jpg'
import rev11 from '../Assets/rev-20.jpg'
import rev12 from '../Assets/rev-12.jpg'
import rev13 from '../Assets/rev-13.jpg'
import rev14 from '../Assets/rev-14.webp'
import rev15 from '../Assets/rev-34.avif'
import rev16 from '../Assets/rev-15.jpg'
import rev17 from '../Assets/rev-22.jpg'
import rev18 from '../Assets/rev-14.webp'
import rev19 from '../Assets/rev23.jpg'
import rev20 from '../Assets/rev-13.jpg'
import rev21 from '../Assets/rev-8.jpg'
import rev22 from '../Assets/rev-24.jpg'
import rev23 from '../Assets/rev-25.jpg'
import rev24 from '../Assets/rev-26.jpg'
import rev25 from '../Assets/rev-9.png'
import rev26 from '../Assets/rev-11.avif'
import rev27 from '../Assets/rev-27.webp'
import rev28 from '../Assets/rev-28.jpg'
import rev29 from '../Assets/rev-29.jpeg'
import rev30 from '../Assets/rev-30.avif'
import rev31 from '../Assets/rev-31.jpg'

const reviewdata= [
    { id: 1, image:rev1, name: "John Stewart", review: "This website is amazing! I got to chat with my favorite celebrity manager and it was an incredible experience." },
  { id: 2, image:rev2, name: "Jack Lewis", review: "A fantastic service! The ability to meet a celebrity through this platform is just phenomenal." },
  { id: 3, image:rev3, name: "Hayley Johnson", review: "Absolutely loved it! The whole process was seamless and enjoyable." },
  { id: 4, image:rev4, name: "Bob Billy", review: "Highly recommend this site. It’s a dream come true for fans!" },
  { id: 5, image:rev5, name: "Will Pullock", review: "A wonderful experience. I got to meet my favorite celebrity and it was unforgettable." },
  { id: 6, image:rev6, name: "Eve Watkins", review: "Such a cool idea! The chat with the manager was so insightful and fun." },
  { id: 7, image:rev7, name: "Kara King", review: "This site exceeded my expectations. Meeting a celebrity has never been easier." },
  { id: 8, image:rev8, name: "Grace Tim", review: "An amazing platform for fans. The interaction was smooth and very enjoyable." },
  { id: 9, image:rev9, name: "Yara Kylian", review: "I had a great time using this service. It’s perfect for any fan!" },
  { id: 10, image:rev10, name: "Ivy Brown", review: "Incredible! The opportunity to chat with a celebrity manager is priceless." },
  { id: 11, image:rev11, name: "Adylyn Smith", review: "A user-friendly site that provides an amazing experience for fans." },
  { id: 12, image:rev12, name: "Foster Rodriguez", review: "I can’t believe I actually got to meet a celebrity! This site is wonderful." },
  { id: 13, image:rev13, name: "Leo Trust", review: "A fantastic way to connect with your favorite celebrities. Highly recommended." },
  { id: 14, image:rev14, name: "Panny Dugh", review: "This site made my dream come true. The chat with the manager was awesome." },
  { id: 15, image:rev15, name: "Nate Stone", review: "A seamless and enjoyable experience. Perfect for any celebrity fan." },
  { id: 16, image:rev16, name: "Phillip Mcgin", review: "Loved every moment of it. The process was easy and exciting." },
  { id: 17, image:rev17, name: "Ashley Moore", review: "An excellent platform. It’s a must-try for anyone who loves celebrities." },
  { id: 18, image:rev18, name: "Reed Quinn", review: "Amazing experience! The chat was fun and meeting the celebrity was the highlight." },
  { id: 19, image:rev19, name: "Rachel Peterson ", review: "This site is a game-changer for fans. Absolutely loved it." },
  { id: 20, image:rev20, name: "Sam Trevor", review: "A great service that brings fans closer to their idols. Highly recommended." },
  { id: 21, image:rev21, name: "Federico Vince", review: "The whole experience was fantastic. I would definitely use this site again." },
  { id: 22, image:rev22, name: "Sharon Cole", review: "Impressive service! I got to meet my favorite celebrity thanks to this site." },
  { id: 23, image:rev23, name: "Olivia Stephens", review: "A wonderful platform for fans. The interaction was smooth and enjoyable." },
  { id: 24, image:rev24, name: "Gabriella White", review: "I had an amazing experience using this site. Highly recommended for all fans." },
  { id: 25, image:rev25, name: "Hunter Fil", review: "This site provides a unique and exciting experience for fans." },
  { id: 26, image:rev26, name: "Harris Chow", review: "A top-notch service that allows fans to meet their idols. Loved it!" },
  { id: 27, image:rev27, name: "Mona Nelson", review: "Fantastic! The chat with the manager was so much fun." },
  { id: 28, image:rev28, name: "Avery Xavier", review: "An incredible experience from start to finish. Highly recommend this site." },
  { id: 29, image:rev29, name: "Bella Adams", review: "This site is perfect for any fan who wants to meet their favorite celebrity." },
  { id: 30, image:rev30, name: "Christy Vol", review: "A user-friendly platform that made my dream of meeting a celebrity come true." },
  { id: 31, image:rev31, name: "Catherine Dan", review: "Loved the experience! The chat was insightful and meeting the celebrity was unforgettable." },
]

export default reviewdata