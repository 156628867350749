import React from "react";

function About() {
    return(
        <div id='about-section' className="w-full p-10 mt-[-350px] sm:mt-[-150px] md:mt-[50px]">
            <h1 className="text-2xl md:text-4xl p-8">About Us</h1>

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
               <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                     </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <h1 className="text-4xl md:text-6xl p-6">
                    CAA delivers world-class oppurtunities to world-class clients.
                </h1>
                <div className="flex flex-col p-3">
                <p className="text-gray-600 ">Positioned at the nexus of talent, content, brands, technology, sports, and live events, CAA creates limitless opportunities for the storytellers, trendsetters, icons, and thought leaders who shape popular culture.</p>

                <p className="text-gray-600 mt-8">Across film, television, music, sports, digital media, marketing, and beyond, we represent thousands of the world’s leading actors, directors, writers, producers, musical artists, comedians, authors, athletes, coaches, broadcasters, teams, leagues, chefs, designers, fashion talent, consumer brands, and more.</p>

                <p className="text-gray-600  mt-8">In service of them, we are committed to remaining the strongest, most vibrant, and most innovative service company in the world.</p>

                <p className="text-gray-600  mt-8">Since our founding in 1975, CAA has continued to deliver on its promise that every client is represented by the whole agency. This collaborative approach benefits our clients now more than ever, as they look to build their careers and their brands across multiple disciplines and platforms.</p>

                <p className="text-gray-600  mt-8">With a global network of employees and strategic partners in North America, Europe, Asia, and beyond, CAA taps its unequaled internal and external ecosystem of experts, relationships, access, and industry intelligence to help ensure that our clients achieve their goals.</p>

                <p className="text-gray-600  mt-8">The trailblazer of the industry, CAA has created an ever-widening scope of services and businesses. We were the first talent agency to build a sports business, create an investment bank, launch a venture fund, found technology start-up companies, establish a business in China, create a brand marketing services business, and launch a philanthropic arm, among other industry innovations.</p>

                <p className="text-gray-600  mt-8">It is also our goal to lead the way in diversity, advocating with marketplace buyers for talent from historically underrepresented groups – and for the cultural and commercial value of inclusion. CAA continues to increase the diversity of our talent roster and our employees, and always will.</p>

                <p className="text-gray-600  mt-8">With a passion matched only by that of our clients, CAA is committed to crafting precedent-setting deals and building new brands and businesses driven by our clients’ ideas, interests, and opportunities.</p>

                </div>
            </div>
            
        </div>
    )
}

export default About