import React from "react";
import Img from "../Assets/IM-R-logo.png"

function Impact() {
    return (
        <div className="w-full p-10 bg-black">
           <h1 className="text-xl text-white font-medium md:text-2xl p-8">Our Impact</h1>

            <div className="relative ">
                <div className="absolute bottom-0 left-0 w-full">
                    <div className="h-0.5 bg-white w-full  mx-auto mb-1"></div> {/* Short line on top */}
                </div>
            </div>

            <div className="max-w-[1240px] p-3 grid md:grid-cols-2 md:mt-10">
                <img
                 src={Img}
                 alt="/img"
                 className="w-[300px] mt-5 md:mt-10"
                />

<div className="flex flex-col p-3">
                    {/* <h1 className="text-white tracking-wide font-medium"></h1> */}
                    <p className="text-white tracking-wide mt-5">Our 2023 Impact Report highlights our recent work, the clients we partnered with, and the communities we served.</p>
                    <p className="text-white tracking-wide mt-5">We continue to expand our efforts in public education, civic engagement, climate change, and crisis response.</p>
                    <p className="text-white tracking-wide mt-5">Together, we have leaned into our ability to convene and served as a source of inspiration and information for our industry. Scalable, sustainable change for good only happens when everything connects!</p>

                    <a href="https://www.flipsnack.com/9A6775DD75E/2023-caa-foundation-impact-report/full-view.html" target='_blank' rel='noopener noreferrer' className="p-3 rounded-full w-[250px] text-center bg-white font-medium mt-10" >2023 IMPACT REPORT</a>
                </div>
            </div>
        </div>
    )
}

export default Impact