// import React, { useState } from 'react';
// import ContactList from './ContactList';
// import ChatWindow from './ChatWindow';


// const ChatApp = () => {
//   const [selectedContact, setSelectedContact] = useState(null);

//   return (
//     <div className="flex flex-col md:flex-row h-screen">
//       <ContactList selectContact={setSelectedContact} />
//       {selectedContact ? (
//         <ChatWindow contact={selectedContact} />
//       ) : (
//         <div className="flex-1 flex justify-center items-center">
//           <h2 className="text-xl">Select a contact to start chatting</h2>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChatApp;




import React, { useState } from 'react';
import ContactList from './ContactList';
import ChatWindow from './ChatWindow';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'; 

const ChatApp = () => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [isContactListVisible, setIsContactListVisible] = useState(false); 

  const toggleContactList = () => {
    setIsContactListVisible(!isContactListVisible);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      
      <div className="md:hidden flex justify-center items-center p-4">
        <button onClick={toggleContactList} className="flex items-center text-lg">
          {isContactListVisible ? (
            <>
              <FiChevronUp className="mr-2" />
              Hide Contacts
            </>
          ) : (
            <>
              <FiChevronDown className="mr-2" />
              Show Contacts
            </>
          )}
        </button>
      </div>

      
      {isContactListVisible || window.innerWidth >= 768 ? ( 
        <ContactList selectContact={setSelectedContact} />
      ) : null}

      
      {selectedContact ? (
        <ChatWindow contact={selectedContact} />
      ) : (
        <div className="flex-1 flex justify-center items-center">
          <h2 className="text-xl">Select a contact to start chatting</h2>
        </div>
      )}
    </div>
  );
};

export default ChatApp;

